import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Bio from '../components/Bio';
import SEO from '../components/seo';
import Button from '../components/Button';
import {
  TeaserBox,
  TeaserRow,
  TeaserSizer,
  BlogTeaserContent,
  TeaserMasonry,
} from '../components/Teaser';
import { rhythm } from '../utils/typography';
import PageTitle from '../components/PageTitle';

const blogQuery = graphql`
  query blogQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { glob: "./**/blog/**/*.md" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Blog(props) {
  const {
    allMarkdownRemark: { edges: blogEdges },
  } = useStaticQuery(blogQuery);
  const blogPosts = blogEdges.map(edge => edge.node);

  return (
    <Layout>
      <SEO title="Blog" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <PageTitle
        title="Bits and Bites!"
        subtitle="I blog about code, Kanban, cooking and other cool stuff."
      />
      <TeaserMasonry>
        {blogPosts.map(blogPost => (
          <TeaserSizer>
            <TeaserBox
              bottomLinkText="📓 Read more!"
              bottomLinkTo={blogPost.fields.slug}
            >
              <BlogTeaserContent blogPost={blogPost} />
            </TeaserBox>
          </TeaserSizer>
        ))}
      </TeaserMasonry>
    </Layout>
  );
}

export default Blog;

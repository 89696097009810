import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Label } from './Label';
import heroStyles from '../styles/Hero.module.scss';

const AuthorLabel = styled(Label)`
  margin-top: -20px;
`;

function Hero({ children }) {
  const {
    avatar,
    site: {
      siteMetadata: { author },
    },
  } = useStaticQuery(graphql`
    query HeroQuery {
      avatar: file(absolutePath: { regex: "/profile-pic-alternative.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      site {
        siteMetadata {
          author
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <section className={heroStyles.hero}>
        <div>
          <div>
            <div className={heroStyles.avatarContainer}>
              <Image
                fluid={avatar.childImageSharp.fluid}
                alt={author}
                style={{
                  marginBottom: 0,
                  width: 180,
                  alignSelf: `center`,
                  borderRadius: `100%`,
                }}
                imgStyle={{
                  borderRadius: `50%`,
                }}
                className={heroStyles.gatsbyImage}
              />
              <AuthorLabel>{author}</AuthorLabel>
            </div>
            {children}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Hero;
